import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SELECTORS } from '@oneaudi/stck-store';
import { TileContext, TileContextState } from './hooks/tileHook';
import { NavigationBar, NavigationItem, Text } from '@audi/audi-ui-react-v2';
import { useDesktopOrMobileView, useServicesContext } from '@oneaudi/vtp-shared';
import { CombinedConfig } from './FeatureHubAppDefinition';
import SuggestedSlider from './components/SuggestedSlider';
import SuggestedMobileWrapper from './components/SuggestedMobileWrapper';
import { SimilarVehiclesState, useSuggestedCars } from './hooks/useSuggestedCars';
import { InitialTab } from '../@types/editor.d';
import VehicleScoreDisclaimer from './components/VehicleScoreDisclaimer';

const Suggested: React.FC = () => {
  const storeViewType = useSelector((state) => SELECTORS.UI.getResultsViewType(state));
  const isDesktop: boolean = useDesktopOrMobileView('1024px') === 'desktop';
  const isTablet: boolean = useDesktopOrMobileView('768px') === 'desktop';
  const isMobile = !isDesktop && !isTablet;
  let initialViewType = storeViewType;
  if (initialViewType === '' || (initialViewType !== 'list' && initialViewType !== 'grid')) {
    initialViewType = 'grid';
  }
  const [viewType, setViewType] = React.useState(initialViewType);

  // here we can set the first tab to be shown via the face Editor
  const configuration = useServicesContext().featureAppConfig as CombinedConfig;
  const { similarVehiclesState, LastVisitedVehiclesState, tabState, setTabState, textKeys } =
    useSuggestedCars();

  const similarVehicleIdsArray: SimilarVehiclesState[] = [];
  similarVehiclesState.forEach((vehicle) => {
    similarVehicleIdsArray.push(vehicle);
  });
  const lastVisitedVehiclesArray: string[] = [];
  LastVisitedVehiclesState.forEach((vehicle) => {
    lastVisitedVehiclesArray.push(vehicle.id);
  });

  const getVehiclesArray = useCallback(
    (tab: InitialTab) => {
      return {
        previouslyViewedCars: lastVisitedVehiclesArray,
        similarCars: similarVehicleIdsArray,
        similarCarsFromDealer: [],
      }[tab];
    },
    [lastVisitedVehiclesArray, similarVehicleIdsArray]
  );

  const getTextKey = useCallback(
    (tab: InitialTab) => {
      return (
        {
          previouslyViewedCars: textKeys.lastVisitedVehicles,
          similarCars: textKeys.similarVehicles,
          similarCarsFromDealer: textKeys.similarVehiclesFromDealer,
        } as const
      )[tab];
    },
    [textKeys]
  );

  const tileContextValue: TileContextState = useMemo(() => {
    return {
      viewType,
      setViewType,
      appContext: 'suggested',
      contentConfiguration: configuration,
    };
  }, [viewType, configuration]);

  return similarVehicleIdsArray.length > 0 || lastVisitedVehiclesArray.length > 0 ? (
    <TileContext.Provider value={tileContextValue}>
      <SuggestedMobileWrapper isMobile={isMobile} data-testid="suggested-provider">
        <Text variant="order3" as="h3" spaceStackEnd="xl">
          {textKeys.headline}
        </Text>
        {!isMobile && (
          <>
            <NavigationBar
              id="tab-group"
              selected={tabState}
              onSelect={(e) => {
                setTabState(e as InitialTab);
              }}
              spaceStackEnd="l"
            >
              {configuration.suggestedCars.tabsEnabled?.map(
                (tab) =>
                  getVehiclesArray(tab).length > 0 && (
                    <NavigationItem id={tab} key={tab}>
                      {getTextKey(tab)}
                    </NavigationItem>
                  )
              )}
            </NavigationBar>
            <VehicleScoreDisclaimer
              tabId={tabState}
              similarVehicles={similarVehicleIdsArray}
              lastVisitedVehicles={lastVisitedVehiclesArray}
              similarVehiclesFromDealer={[]}
              textKeys={textKeys}
            />
          </>
        )}
        {configuration.suggestedCars.tabsEnabled?.map((tab) => (
          <SuggestedSlider
            vehicles={getVehiclesArray(tab)}
            showSlider={tabState === tab}
            isMobile={isMobile}
            tabText={getTextKey(tab)}
            tabId={tab}
            key={tab}
            textKeys={textKeys}
          />
        ))}
      </SuggestedMobileWrapper>
    </TileContext.Provider>
  ) : null;
};

export default Suggested;
